const exp = {
  /*
  contact: {
    description: 'official channels',
    list: [
      {
        type: 'info',
        label: 'website',
        content: 'https://kanon.art/'
      },
      {
        type: 'info',
        label: 'twitter:',
        content: '`https://twitter.com/kanondotart`'
      },
      {
        type: 'info',
        label: 'github:',
        content: 'https://github.com/kanonart/Daemonica'
      },
      {
        type: 'info',
        label: 'email',
        content: 'info@kanon.art'
      }
    ]
  }*/
};

export default exp;
