
const addresses = {
  local: {
    k21: '0x5Bf9b9a82A3E81E398d686d2B02931c6c8ff8A21',
    klist: '0x2175B6B2219DcaAf7020CdE8F2B59e0a6F373D45',
    daemonica: '0x28e233C66c4190a7f975112440ff369E616D51e7',
    xe_ntity: '0x709265B376897F4611C67842719F28ABC1dD9f1B',
    entity: '0xC94b997C29886AC3017388Cc4B70933cE6549bC6',
    n: '0xfc6328fAb876255578641E98517155bCcAB7f05a',
    loot: '0xC827D501572687568b0e22F38A0584490dCf28c0',
    bloot: '0xb8aE40DDdc63b387B8F14B54F785932Acce99BA4',
  },
  rinkeby: {
    daemonica: '0x8313c8074D69F9b07d395bA5089c6D5F8e674f79',
    entity: '0x3b7F72BAB546112aB08F9f447F1d677E3BEa31f4',
    xe_ntity: '0x5CF1ef91a37DAcabD917651C9F0aFfd5d9d8a002',
    klist: '0x8B6aa9570Dd271870D70dDdf775c940120bAE188',
    k21: '0x8F061e3b0c94FaE91cb2a4b38d452Ec2e1B07D07',
    n: '0xABcFA3491E84b8218f90515d6b80a71A85D89443',
    loot: '0x213bb136a3Eb9EA720A843F611ec2a17fA0b3A74',
    bloot: '0xAd9AdFa294459b6E8F692B3B0E2ED1536856b935'
  },
  mainnet: {
    k21: '0xb9d99c33ea2d86ec5ec6b8a4dd816ebba64404af',
    klist: '0x3BEf4E7b4f5df0AA0dEe329f77a0A50c2F499Ac2',
    daemonica: '0xaB90665CA0EE7783f2A841d68A6276D3dB8285e2',
    xe_ntity: '0x6c0312933B695cA75858C2c6eC732A89Ff312C85',
    entity: '0x677aB613FcfbB9f595035422bA7e2f7E6a822d7A'

  }
};

module.exports = {
  addresses
}
