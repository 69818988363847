import utils from "./utils"



const exp = {
  guide: {
    description: 'instructions for interacting with an entity',
    run(print) {
      return new Promise(resolve => {
        resolve(utils.renderGui());
      })
    }
  },
  connect: {
    description: 'connect your Ethereum wallet',
    run(print, input) {
      return new Promise((resolve, reject) => {

        utils.getWeb3().then(function(web3){
          utils.loadContracts(web3).then(function(message){
            for(let i = 0; i < message.length-1; i++){
              print(message[i]);
            }
            resolve([message[message.length-1]]);
          }).catch((err) => {
            utils.logError(err);
            resolve([utils.errorMessage(err.message)]);
          });
        }).catch((err) => {
          utils.logError(err);
          resolve([utils.errorMessage(err.message)]);
        });
      });
    }
  },
  reboot: {
    description: 'reload the Grimoire\n\n',
    run(print) {
      let i = 0
      return new Promise(resolve => {
        const interval = setInterval(() => {
          print(utils.boot[i])
          i++
          if (!utils.boot[i]) {
            clearInterval(interval)
            console.log('logo:');
            console.dir(utils.getLogo())
            resolve([{ type: 'info', color: 'white', content: 'Type <strong>connect</strong> to start'}, utils.getLogo()])
          }
        }, 50)
      })
    }
  },
  ordo: {
    description: 'door\n\n',
    run(print) {
      return new Promise(resolve => {
        resolve([utils.systemMessage('Untitled\npencil on paper, 2021\nthe artist'), utils.getOrdo()])
      })
    }
  },
  entity: {
    description: 'define entity, mint the next or multiple entities, render an entity by id (and, optionally, by tick), or list your entities by id with the following commands:\n\nentity <em>def</em>\nentity <em>animo</em>\nentity <em>animo</em> --n=#\nentity <em>manifest</em> --id=#\nentity <em>manifest</em> --id=# --tick=#\nentity <em>hodlings</em>\n\n',
    run(print, input, params) {
      return new Promise((resolve, reject) => {
        let i;

        if(input === "def"){
          resolve([utils.systemMessage('An entity is an 8 x 8 numerical matrix. An entity is composed of 8 properties. A property consists of 8 natural numbers. Each entity has a name, which reflects the numerical correlations within an entity. A name is a concatenation of ngrams from dead languages, heretical dialects, and ancient tongues. A special ratio ≈ n / f > 0.125 determines the length of the name and the frequency of the delimiter to optimize a name’s daemonic status onchain. The order is the enchantment. What is enchanted finds a true place.'), utils.getEntityBanner()]);
        }else if(input === "animo"){
          if(utils.connected()){
            resolve(utils.errorMessage('animo disabled\nall currently available entities have already been ensouled\n'));
/*
            let valid = false;
            let n;
            for(i = 0; i < params.length; i++){
              if(params[i].substring(0,4) === "--n="){
                n = parseInt(params[i].substring(4));
                valid = true;
              }
            }
            if(valid){
              utils.entityAnimo(n).then((msg) => {
                resolve(msg);
              }).catch((err) => {
                resolve([utils.errorMessage(err.message)])
              });
            }else{
              utils.entityAnimo(1).then((msg) => {
                resolve(msg);
              }).catch((err) => {
                resolve([utils.errorMessage(err.message)])
              });
            }
*/
          }else{
            resolve([utils.errorMessage(utils.errorlist.notconnected)]);
          }
        }else if(input === "manifest"){
          if(utils.connected()){
            let valid = false;
            let id;
            let tick = -1;
            for(i = 0; i < params.length; i++){
              if(params[i].substring(0,5) === "--id="){
                id = parseInt(params[i].substring(5));
                valid = true;
              }
              if(params[i].substring(0,7) === "--tick="){
                tick = parseInt(params[i].substring(7));
              }
            }
            if(valid){
              utils.manifestEntity(id, tick).then((msg) => {
                resolve(msg);
              }).catch((err) => {
                resolve([utils.errorMessage(err.message)]);
              });
            }else{
              resolve([utils.errorMessage('Invalid param, must pass token id with --id=#\nE.g. <strong>entity manifest --id=387</strong>')]);
            }
          }else{
            resolve([utils.errorMessage(utils.errorlist.notconnected)]);
          }
        }else if(input === "cast"){
          if(utils.connected()){
            print(utils.gasWarning);

            let valid = false;
            let id;
            for(i = 0; i < params.length; i++){
              if(params[i].substring(0,5) === "--id="){
                id = parseInt(params[i].substring(5));
                valid = true;
              }
            }
            if(valid){
              utils.castEntity(id).then((msg) => {
                resolve(msg);
              }).catch((err) => {
                resolve([utils.errorMessage(err.message)]);
              });
            }else{
              resolve([utils.errorMessage('Invalid param, must pass token id with --id=#\nE.g. <strong>entity cast --id=387</strong>')]);
            }
          }else{
            resolve([utils.errorMessage(utils.errorlist.notconnected)]);
          }
        }else if(input === "hodlings"){
          if(utils.connected()){
            utils.listEntities().then((msg) => {
              resolve(msg);
            }).catch((err) => {
              resolve([utils.errorMessage(err.message)]);
            });
          }else{
            resolve([utils.errorMessage(utils.errorlist.notconnected)]);
          }
        }else{
          resolve([utils.errorMessage(utils.errorlist.noop)]);
        }
      });
    }
  },
  xe_ntity: {
    description: 'define a xe_ntity, render a xe_ntity by id or list your xe_ntities by id with the following commands:\n\nxe_ntity <em>def</em>\nxe_ntity <em>manifest</em> --id=#\nxe_ntity <em>hodlings</em>\n\n',
    run(print, input, params) {
      return new Promise((resolve, reject) => {

        if(input === "def"){
          resolve([utils.systemMessage('A Xe_ntity is imbued with the power to “shapeshift” any Daemonic Entity by changing that Entity\'s properties. A Xe.ntity owner can cast it on an Entity they own to transform that Entity’s numerical composition. A Xe_ntity is a secularized incantation expressed as a function. The function is what enchants an entity into another order. This other order is called ξ.'), utils.getXe_ntityBanner()]);
        }else if(input === "manifest"){
          if(utils.connected()){
            let valid = false;
            let id;
            for(var i = 0; i < params.length; i++){
              if(params[i].substring(0,5) === "--id="){
                id = parseInt(params[i].substring(5));
                valid = true;
              }
            }
            if(valid){
              utils.manifestXe_ntity(id).then((msg) => {
                resolve(msg);
              }).catch((err) => {
                resolve([utils.errorMessage(err.message)]);
              });
            }else{
              resolve([utils.errorMessage('Invalid param, must pass token id with --id=#\nE.g. <strong>xe_ntity manifest --id=387</strong>')]);
            }
          }else{
            resolve([utils.errorMessage(utils.errorlist.notconnected)]);
          }
        }else if(input === "hodlings"){
          if(utils.connected()){
            utils.listXe_ntities().then((msg) => {
              resolve(msg);
            }).catch((err) => {
              resolve([utils.errorMessage(err.message)]);
            });
          }else{
            resolve([utils.errorMessage(utils.errorlist.notconnected)]);
          }
        }else{
          resolve([utils.errorMessage(utils.errorlist.noop)]);
        }
      });
    }
  },
  klist: {
    description: '(K21 hodlers only) claim a KLIST membership token, render it, and list hodlings by token id with the following commands:\n\nklist <em>claim</em>\nklist <em>render</em>\nklist <em>render</em> --id=#\nklist <em>hodlings</em>\n\n',
    run(print, input, params) {
      return new Promise((resolve, reject) => {

        if(input === "claim"){
          if(utils.connected()){
            print(utils.gasWarning);

            utils.claimKlist().then((msg) => {
              resolve(msg);
            }).catch((err) => {
              resolve([utils.errorMessage(err.message)]);
            });
          }else{
            resolve([utils.errorMessage(utils.errorlist.notconnected)]);
          }
        }else if(input === "render"){
          if(utils.connected()){
            if(params.length === 0){
              utils.renderKlist(-1).then((msg) => {
                resolve(msg);
              }).catch((err) => {
                resolve([utils.errorMessage(err.message)]);
              });
            }else{
              let valid = false;
              let id;
              for(var i = 0; i < params.length; i++){
                if(params[i].substring(0,5) === "--id="){
                  id = parseInt(params[i].substring(5));
                  valid = true;
                }
              }
              if(valid){
                utils.renderKlist(id).then((msg) => {
                  resolve(msg);
                }).catch((err) => {
                  resolve([utils.errorMessage(err.message)]);
                });
              }else{
                resolve([utils.errorMessage('Invalid param, must pass token id with --id=#\nE.g. <strong>xe_ntity manifest --id=387</strong>')]);
              }
            }
          }else{
            resolve([utils.errorMessage(utils.errorlist.notconnected)]);
          }
        }else if(input === "hodlings"){
          if(utils.connected()){
            utils.listKlist().then((msg) => {
              resolve(msg);
            }).catch((err) => {
              resolve([utils.errorMessage(err.message)]);
            });
          }else{
            resolve([utils.errorMessage(utils.errorlist.notconnected)]);
          }
        }else{
          resolve([utils.errorMessage(utils.errorlist.noop)]);
        }
      });
    }
  },
  dims: {
    description: 'list currently registered dims in the Daemonica universe or add a new one using following commands:\n\ndims <em>list</em>\ndims <em>add</em> --address=0x#\n\n',
    run(print, input, params) {
      return new Promise((resolve, reject) => {

        if(input === "add"){
          if(utils.connected()){
            if(params[0].substring(0,10) === "--address=" && params[0].length === 52){
              print(utils.dimAddWarning);

              utils.addDim(params[0].substring(10)).then((msg) => {
                resolve(msg);
              }).catch((err) => {
                resolve([utils.errorMessage(err.message)]);
              });
            }else{
              resolve([utils.errorMessage('Invalid param, must pass dim contract address with --address=#\nE.g. <strong>dims add --address=0xfc6328fAb876255578641E98517155bCcAB7f05a</strong>')]);
            }
          }else{
            resolve([utils.errorMessage(utils.errorlist.notconnected)]);
          }
        }else if(input === "list"){
          if(utils.connected()){
            utils.listDims().then((msg) => {
              resolve(msg);
            }).catch((err) => {
              resolve([utils.errorMessage(err.message)]);
            });
          }else{
            resolve([utils.errorMessage(utils.errorlist.notconnected)]);
          }
        }else{
          resolve([utils.errorMessage(utils.errorlist.noop)]);
        }
      });
    }
  },
  sudo: {
    description: 'admin only\n\n',
    run(print, input, params) {
      return new Promise((resolve, reject) => {
        utils.getWeb3().then((rtn) => {

          if(input === "artist"){
            console.log('getArtist')
            utils.getArtist().then((msg) => {
              resolve(msg);
            }).catch((err) => {
              resolve([utils.errorMessage(err.message)]);
            });
          }
          if(input === "klist"){
            if(params[0] === "initialize"){
              if(params[1].substring(0,10) === "--address=" && params[1].length === 52){
                utils.initKlist(params[1].substring(10)).then((msg) => {
                  resolve(msg);
                }).catch((err) => {
                  resolve([utils.errorMessage(err.message)]);
                });
              }
            }else if(params[0] === "distro"){
              utils.distroKlist().then((msg) => {
                resolve(msg);
              }).catch((err) => {
                resolve([utils.errorMessage(err.message)]);
              });
            }
          }else if(input === "entity"){
            console.log('sudo entity');
            if(params[0] === "claim"){
              utils.entityAdminClaim(20).then((msg) => {
                resolve(msg);
              }).catch((err) => {
                resolve([utils.errorMessage(err.message)]);
              });
            }
            if(params[0] === "withdraw"){
              console.log('sudo entity withdraw')
              utils.entityWithdraw().then((msg) => {
                resolve(msg);
              }).catch((err) => {
                resolve([utils.errorMessage(err.message)]);
              });
            }
          }else if(input === "xe_ntity"){
            if(params[0] === "withdraw"){
              utils.xe_ntityWithdraw().then((msg) => {
                resolve(msg);
              }).catch((err) => {
                resolve([utils.errorMessage(err.message)]);
              });
            }
          }else if(input === "daemonica"){
            if(params[0] === "withdraw"){
              utils.daemonicaWithdraw().then((msg) => {
                resolve(msg);
              }).catch((err) => {
                resolve([utils.errorMessage(err.message)]);
              });
            }
          }else if(input === "dims"){
            if(params[0] === "remove"){
              if(params[1].substring(0,9) === "--symbol="){

                utils.removeDim(params[1].substring(9)).then((msg) => {
                  resolve(msg);
                }).catch((err) => {
                  resolve([utils.errorMessage(err.message)]);
                });
              }else{
                resolve([utils.errorMessage('Invalid param, must pass dim contract address with --address=#\nE.g. <strong>dims add --address=0xfc6328fAb876255578641E98517155bCcAB7f05a</strong>')]);
              }
            }else if(params[0] === "add"){
              if(params[1].substring(0,10) === "--address=" && params[1].length === 52){
                utils.adminAddDim(params[1].substring(10)).then((msg) => {
                  resolve(msg);
                }).catch((err) => {
                  resolve([utils.errorMessage(err.message)]);
                });
              }else{
                resolve([utils.errorMessage('Invalid param, must pass dim contract address with --address=#\nE.g. <strong>dims add --address=0xfc6328fAb876255578641E98517155bCcAB7f05a</strong>')]);
              }
            }
          }else if(input === "presale"){
            if(params[0] === 'true'){
              utils.setPresale(true).then((msg) => {
                resolve(msg);
              }).catch((err) => {
                resolve([utils.errorMessage(err.message)]);
              });
            }else if(params[0] === 'false'){
              utils.setPresale(false).then((msg) => {
                resolve(msg);
              }).catch((err) => {
                resolve([utils.errorMessage(err.message)]);
              });
            }else{
              utils.getPresale().then((msg) => {
                resolve(msg);
              }).catch((err) => {
                resolve([utils.errorMessage(err.message)]);
              });
            }

          }else if(input === "claim"){
            if(params[0] === "n"){

              utils.claimN().then((msg) => {
                resolve(msg);
              }).catch((err) => {
                resolve([utils.errorMessage(err.message)]);
              });

            }else if(params[0] === 'loot'){
              console.log('claim loot')

              utils.claimLoot().then((msg) => {
                resolve(msg);
              }).catch((err) => {
                resolve([utils.errorMessage(err.message)]);
              });

            }else if(params[0] === 'bloot'){
              console.log('claim bloot')

              utils.claimBloot().then((msg) => {
                resolve(msg);
              }).catch((err) => {
                resolve([utils.errorMessage(err.message)]);
              });

            }
          }else{
            resolve([utils.errorMessage(params[0] + ': not claimable')]);
          }
        }).catch((err) => {
          utils.logError(err);
          resolve([utils.errorMessage(err.message)]);
        });
      });
    }
  },
};

export default exp;
