import Web3 from "web3";
import Daemonica from "../contracts/Daemonica.json";
import Entity from "../contracts/Entity.json";
import Xe_ntity from "../contracts/Xe_ntity.json";
import KLIST from "../contracts/KLIST.json";
import K21 from "../contracts/K21.json";
import N from "../contracts/N.json";
import Loot from "../contracts/Loot.json";
import Bloot from "../contracts/Bloot.json";

//import gui from "./gui";

import addressbook from "./addresses";
let addresses;

//var markdown = require( "markdown" ).markdown;


const offering = Web3.utils.toWei('0.088', 'ether');

const state = {
  web3: null,
  account: null,
  entity: null,
  daemonica: null,
  xe_ntity: null,
  klist: null,
  k21: null,
  klistTokenId: null
};


const b64DecodeUnicode = (str) => {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(atob(str).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
}

const errorMessage = (message) => {
  return { type: 'error', label: '(>_<)', color: 'red', content: "<strong>ERROR</strong>\n" + message };
}

const successMessage = (message) => {
  return { type: 'success', label: '(^_^)', color: 'green', content: "<strong>SUCCESS</strong>\n" + message };
}

const systemMessage = (message) => {
  return { type: 'system', color: 'white', content: message };
}

const linebreak = () => {
  return { label: '', color: 'white', content: '<br/>' };
}

const parseRPCError = (err) => {
  let message;
  let prefix = "[ethjs-query] while formatting outputs from RPC '";
  let prefix2 = "Internal JSON-RPC error.";
  let prefix3 = "execution reverted";
  if(err.message.substring(0, prefix.length) === prefix){
    message = err.message.substring(prefix.length);
    message = message.substring(0, message.length-1);
    message = JSON.parse(message);
    message = message.value.data.message;
  }else if(err.message.substring(0, prefix2.length) === prefix2){
    message = err.message.substring(prefix2.length+1);
    //message = message.substring(0, message.length-1);
    message = JSON.parse(message);
    message = message.message;
  }else if(err.message.substring(0, prefix3.length) === prefix3){
    message = err.message + '\nSee console for details';
  }else{
    message = err.message + '\nSee console for details';
  }
  return [errorMessage(message)];
}

const timeNow = () => {
 const date = new Date()
 const hours = date.getHours()
 const minutes = date.getMinutes()
 const seconds = date.getSeconds()
 return `${hours}${minutes < 10 ? ':0' : ':'}${minutes}${seconds < 10 ? ':0' : ':'}${seconds}`
}


const errors = {
  notconnected: 'No wallet connected, type <strong>connect</strong> to load Ethereum blockchain',
  unsuccessful: 'Unsuccessful, try again',
  noop: 'Invalid operation\nDaemonica Grimoire spells take the form <strong>noun verb --param=value</strong>\nType <strong>spells</strong> for a list of available operations'
}


const getNFTName = (json, type) => {
  if(json["name"] === undefined){
    return type;
  }else{
    if(json["name"] === "invalid KLIST Membership (v0)"){
      return "invalid KLIST token";
    }
    return json["name"];
  }
}

const formatJSON = (json) => {
  console.log("json")
  console.dir(json)
  let message = '';
  let k = [];
  let v = [];

  Object.keys(json).forEach(item => {
    k.push(item);
  });

  for( let prop in json ){
    v.push(json[prop]);
  }

  let prefix = "Daemonica entity";
  let prefixXe_ntity = "Xe_ntity";

  if(v[0] === "KLIST Membership (v0)"){
    k[0] = "version";
    v[0] = "0";
    if(json["attributes"] !== undefined){
      k.push('tier');
      v.push(json["attributes"][1].value + '/' + json["attributes"][2].totalTiers);//tier
    }
  }else if(v[0] === "invalid KLIST Membership (v0)"){
    k[0] = "version";
    v[0] = "n/a";
    k.push('tier');
    v.push('n/a');
  }else if(v[1].substring(0, prefix.length) === prefix){
    if(json["attributes"] !== undefined){
      k.push('tick');
      v.push(json["attributes"][0].tick);//tick

      let dimCount = json["attributes"][1].value;
      let dims = [];
      k.push(dimCount + "-dimensional");
      for(var j = 2; j < dimCount+2; j++){
        dims.push(json["attributes"][j].value);
      }
      v.push(dims.join(", "));
    }
  }else if(v[0].substring(0, prefixXe_ntity.length) === prefixXe_ntity){
    if(json["attributes"] !== undefined){
      k.push('entity');
      v.push(json["attributes"][0].entity);//tick
      k.push('tick');
      v.push(json["attributes"][1].tick);//tick
    }
  }

  for(var i = 0; i < k.length; i++){
    if(k[i] !== "image" && k[i] !== "attributes" && k[i] !== "description"){
      if(k[i] === "manifested"){
        if(parseInt(v[i]) === 0){
          message += k[i] + ": not yet\n";
        }else{
          var d = new Date(v[i]*1000);
          message += k[i] + ": " + d + '\n';
        }
      }else{
        message += k[i] + ": " + v[i] + '\n';
      }
    }
  }
  return message;
}


const exp = {
  getAccount() {
    return state.account;
  },
  gasWarning: {
    label: '(¬_¬)',
    type: 'warning',
    color: 'yellow',
    content: '<strong>WARNING</strong>\nIf gas cost is extrememly high, your transaction is likely to be unsuccessful and you may want to reject it'
  },
  dimAddWarning: {
    label: '(¬_¬)',
    type: 'warning',
    color: 'yellow',
    content: '<strong>WARNING</strong>\nAdding a dim costs 1 ether'
  },
  connected() {
    if(state.web3 === null){
      return false;
    }else{
      return true;
    }
  },
  boot: [
    {
      type: 'system',
      color: 'white',
      label: '八八',
      content: '<strong>Daemonica Grimoire v0.16.1</strong>'
    },
    linebreak(),linebreak(),linebreak(),linebreak(),linebreak(),linebreak(),linebreak(),
    {type: 'system', color: 'white', content: 'Daemonica uses OccultMath to generate 8 x 8 numerical matrices from the other onchain NFTs you hold in your wallet. Matrices take the form of "entities" and "xe_ntities." The n-dimensional relationships that exist within and between each can be freely interpreted and understood. Use Daemonica and this interactive grimoire however you wish.'},
    linebreak(),
    {type: 'system', color: 'white', content: 'Type <strong>guide</strong> for instructions'},
    linebreak(),
    { time: timeNow(), type: 'system', color: 'white', content: 'Grimoire ready' },
    linebreak(),
    { type: 'info', color: 'white', label: 'i', content: 'Type <strong>spells</strong> for a list of commands' },
  ],
  getTime() {
    return timeNow();
  },
  errorMessage(message) {
    return { type: 'error', label: '(>_<)', color: 'red', content: "<strong>ERROR</strong>\n" + message };
  },
  systemMessage(message) {
    return { type: 'system', color: 'white', content: message };
  },
  logError(err) {
    console.log("ERROR:");
    console.dir(err);
  },
  errorlist: errors,
  getWeb3: async () => {
    // Modern dapp browsers...
    if (window.ethereum) {
     const web3 = new Web3(window.ethereum);
     try {
       // Request account access if needed
       await window.ethereum.enable();
       // Accounts now exposed
       state.web3 = web3;
       return web3;
     } catch (error) {
       new Error(error);
     }
    }
    // Legacy dapp browsers...
    else if (window.web3) {
     // Use Mist/MetaMask's provider.
     const web3 = window.web3;
     console.log("Injected web3 detected.");
     state.web3 = web3;
     return web3;
    }
    // Fallback to localhost; use dev console port by default...
    else {
     const provider = new Web3.providers.HttpProvider(
       "http://127.0.0.1:8545"
     );
     const web3 = new Web3(provider);
     console.log("No web3 instance injected, using Local web3.");
     state.web3 = web3;
     return web3;
    }
  },
  loadContracts: async (web3) => {
    try {
     // Use web3 to get the user's accounts.
     const accounts = await web3.eth.getAccounts();
     state.account = accounts[0];

     // Get the contract instance.
     const networkId = await web3.eth.net.getId();
     console.log('networkId: ' + networkId);
     console.log('addressbook')
     console.dir(addressbook)
     if(parseInt(networkId) === 1337){//
       addresses = addressbook.addresses.local;
     }else if(parseInt(networkId) === 4){//rinkeby
       addresses = addressbook.addresses.rinkeby;
     }else if(parseInt(networkId) === 1){//mainnet
       addresses = addressbook.addresses.mainnet;
     }

     console.log('addresses:')
     console.dir(addresses)


     //const deployedNetworkDaemonica = Daemonica.networks[networkId];
     state.daemonica = new web3.eth.Contract(
       Daemonica.abi,
       state.web3.utils.toChecksumAddress(addresses.daemonica)//deployedNetworkDaemonica && deployedNetworkDaemonica.address,
     );

     //const deployedNetworkEntity = Entity.networks[networkId];
     state.entity = new web3.eth.Contract(
       Entity.abi,
       state.web3.utils.toChecksumAddress(addresses.entity)//deployedNetworkEntity && deployedNetworkEntity.address,
     );

     //const deployedNetworkXe_ntity = Xe_ntity.networks[networkId];
     state.xe_ntity = new web3.eth.Contract(
       Xe_ntity.abi,
       state.web3.utils.toChecksumAddress(addresses.xe_ntity)//deployedNetworkXe_ntity && deployedNetworkXe_ntity.address,
     );

     //const deployedNetworkKlist = KLIST.networks[networkId];
     state.klist = new web3.eth.Contract(
       KLIST.abi,
       state.web3.utils.toChecksumAddress(addresses.klist)//deployedNetworkKlist && deployedNetworkKlist.address,
     );

     //const deployedNetworkK21 = K21.networks[networkId];
     state.k21 = new web3.eth.Contract(
       K21.abi,
       state.web3.utils.toChecksumAddress(addresses.k21)//deployedNetworkK21 && deployedNetworkK21.address,
     );

     return [
       { type: 'success', label: '(^_^)', color: 'green', content: '<strong>LOADED</strong>' },
       { type: 'success', label: '', color: 'green', content: '<strong>Daemonica contract</strong> at ' + addresses.daemonica },
       { type: 'success', label: '', color: 'green', content: '<strong>Entity contract</strong> at ' + addresses.entity },
       { type: 'success', label: '', color: 'green', content: '<strong>Xe_ntity contract</strong> at ' + addresses.xe_ntity },
       { type: 'success', label: '', color: 'green', content: '<strong>KLIST contract</strong> at ' + addresses.klist },
       linebreak(),
       { time: timeNow(), type: 'input', color: 'white', label: '', content: 'Ethereum blockchain connected' }
     ];

    } catch (err) {
     // Catch any errors for any of the above operations.
     console.log('ERROR:')
     console.dir(err);
     return parseRPCError(err);
     //return [errorMessage(err)];
     //return [errorMessage('Failed to connect to Ethereum blockchain -- check console for details')];
   }
  },
  entityAdminClaim: async (n) => {
    try{
      console.log('animo ' + n)
      const { entity } = state;
      if(entity === null){
       return [errorMessage(this.notconnected)];
      }

      let receipt;
      if(n <= 1){
        receipt = await entity.methods.animo().send({ from: state.account });
      }else{
        receipt = await entity.methods.animoMulti(n).send({ from: state.account});
      }

      console.log("receipt")
      console.dir(receipt);
      console.dir(receipt.events.Transfer.returnValues);

      if(receipt.status){
        if(n <= 1){
          return [successMessage('Manifested entity ' + receipt.events.Transfer.returnValues.tokenId + '\nType <strong>entity manifest --id=' + receipt.events.Transfer.returnValues.tokenId + '</strong> to see it')];
        }else{
          let ids = [];
          for(var i = 0; i < receipt.events.Transfer.length; i++){
            ids.push(receipt.events.Transfer[i].returnValues.tokenId);
          }
          return [successMessage('Manifested entities with token ids: ' + ids.join(', ') + '\nType <strong>entity manifest --id=#</strong> to see each')];
        }
      }else{
       return [errorMessage(this.unsuccessful)];
      }
    }catch (err) {
      console.log('ERROR:');
      console.dir(err);
      return parseRPCError(err);
    }
  },
  entityAnimo: async (n) => {
    try{
      console.log('animo ' + n)
      const { entity } = state;
      if(entity === null){
       return [errorMessage(this.notconnected)];
      }

      let receipt;
      if(n <= 1){
        receipt = await entity.methods.animo().send({ from: state.account, value: offering });
      }else{
        receipt = await entity.methods.animoMulti(n).send({ from: state.account, value: offering*parseInt(n) });
      }

      console.log("receipt")
      console.dir(receipt);
      console.dir(receipt.events.Transfer.returnValues);

      if(receipt.status){
        if(n <= 1){
          return [successMessage('Manifested entity ' + receipt.events.Transfer.returnValues.tokenId + '\nType <strong>entity manifest --id=' + receipt.events.Transfer.returnValues.tokenId + '</strong> to see it')];
        }else{
          let ids = [];
          for(var i = 0; i < receipt.events.Transfer.length; i++){
            ids.push(receipt.events.Transfer[i].returnValues.tokenId);
          }
          return [successMessage('Manifested entities with token ids: ' + ids.join(', ') + '\nType <strong>entity manifest --id=#</strong> to see each')];
        }
      }else{
       return [errorMessage(this.unsuccessful)];
      }
    }catch (err) {
      console.log('ERROR:');
      console.dir(err);
      return parseRPCError(err);
    }
  },
  manifestEntity: async (tokenId, tick) => {
    try{
      const { entity } = state;



      let uri;
      if(tick !== -1){
        console.log("entity --id=" + tokenId + " --tick=" + tick);
        uri = await entity.methods.tokenURI(tokenId, tick).call();
      }else{
        console.log("entity --id=" + tokenId);
        uri = await entity.methods.tokenURI(tokenId).call();
      }

      let payloadPrefix = 'data:application/json;base64,';
      let payload = uri.substring(payloadPrefix.length);
      payload = b64DecodeUnicode(payload);
      //payload = atob(payload);


      console.dir(payload);

      let payloadJSON = JSON.parse(payload);
      let name = getNFTName(payloadJSON, "entity");
      payload = formatJSON(payloadJSON);

      let svgPrefix = 'data:image/svg+xml;base64,';
      let svg = payloadJSON.image.substring(svgPrefix.length);
      svg = atob(svg);

      const width = 300;
      const left = window.innerWidth - width - 30;//20px from right side

      return [successMessage('Manifested entity\nid: ' + tokenId + "\n" + payload), { type: 'svg', color: 'white', left: left, width: width + 'px', title: name, payload: svg}];
    }catch (err) {
      console.log('ERROR:');
      console.dir(err);
      return parseRPCError(err);
    }
  },
  castEntity: async (tokenId) => {
    try{
      const { entity } = state;
      if(entity === null){
        return [errorMessage(errors.notconnected)];
      }
      const receipt = await entity.methods.cast(tokenId).send({ from: state.account, value: offering/10 })

      const msg = 'Casted entity ' + tokenId + ', type <strong>entity manifest --id=' + tokenId + '</strong> to see it transformed\nMinted xe_ntity ' + receipt.events.Transfer.returnValues.tokenId + ', type <strong>xe_ntity manifest --id=' + receipt.events.Transfer.returnValues.tokenId + '</strong> to see it';

      if(receipt.status){
        return [successMessage(msg)];
      }else{
        return [errorMessage(errors.unsuccessful)];
      }

    }catch (err) {
      console.log("cast error: ");
      console.dir(err);
      return parseRPCError(err);
    }
  },
  listEntities: async () => {
    try{
      const { entity } = state;
      if(entity === null){
        return [errorMessage(errors.notconnected)];
      }

      let message;
      let tokenIds = [];
      const total = await entity.methods.balanceOf(state.account).call();
      console.log('list entities: total: ' + total)
      console.dir(total)
      if(parseInt(total) === 0){
        message = "You do not hold any Daemonica entities\nType <strong>entity animo</strong> to attempt to mint one";
      }else if(parseInt(total) === 1){
        tokenIds[0] = await entity.methods.tokenOfOwnerByIndex(state.account, 0).call();
        message = "You hold the entity with token id: " + tokenIds[0];
      }else{
        for(var i = 0; i < total; i ++){
          tokenIds[i] = await entity.methods.tokenOfOwnerByIndex(state.account, i).call();
        }
        message = "You hold the entities with token id: " + tokenIds.join(', ');
      }

      return [systemMessage(message)];
    }catch (err) {
      console.log("cast error: ");
      console.dir(err);
      return parseRPCError(err);
    }
  },
  listXe_ntities: async () => {
    try{
      const { xe_ntity } = state;
      if(xe_ntity === null){
        return [errorMessage(errors.notconnected)];
      }

      let message;
      let tokenIds = [];
      const total = await xe_ntity.methods.balanceOf(state.account).call();
      if(parseInt(total) === 0){
        message = "You do not hold any Daemonica xe_ntities";
      }else if(parseInt(total) === 1){
        tokenIds[0] = await xe_ntity.methods.tokenOfOwnerByIndex(state.account, 0).call();
        message = "You hold the xe_ntity with token id: " + tokenIds[0];
      }else{
        for(var i = 0; i < total; i ++){
          tokenIds[i] = await xe_ntity.methods.tokenOfOwnerByIndex(state.account, i).call();
        }
        message = "You hold the xe_ntities with token id: " + tokenIds.join(', ');
      }

      return [systemMessage(message)];
    }catch (err) {
      console.log("cast error: ");
      console.dir(err);
      return parseRPCError(err);
    }
  },
  listKlist: async () => {
    try{
      const { klist } = state;
      if(klist === null){
        return [errorMessage(errors.notconnected)];
      }

      let message;
      let tokenIds = [];
      const total = await klist.methods.balanceOf(state.account).call();
      if(parseInt(total) === 0){
        message = "None: you are not a KLIST member\nType <strong>klist claim</strong> if you hold K21 tokens";
      }else if(parseInt(total) === 1){
        tokenIds[0] = await klist.methods.tokenOfOwnerByIndex(state.account, 0).call();
        message = "You are a KLIST member with token id: " + tokenIds[0];
      }else{
        for(var i = 0; i < total; i ++){
          tokenIds[i] = await klist.methods.tokenOfOwnerByIndex(state.account, i).call();
        }
        message = "You are a KLIST member with token ids: " + tokenIds.join(', ');
      }

      return [systemMessage(message)];
    }catch (err) {
      console.log("ERROR:");
      console.dir(err);
      return parseRPCError(err);
    }
  },
  claimKlist: async () => {
    try{
      const { klist } = state;

      if(klist === null){
        return [errorMessage(errors.notconnected)];
      }

      const receipt = await klist.methods.claim().send({ from: state.account });

      if(receipt.status === true){
        return [successMessage('Claimed KLIST token\nType <strong>klist render</strong> to see it')];
      }else{
        return [errorMessage(errors.unsuccessful)];
      }
    }catch (err) {
      console.log("claim KLIST error: ");
      console.dir(err);
      return parseRPCError(err);
    }
  },
  initKlist: async (k21address) => {
    try{
      const { klist } = state;

      if(klist === null){
        return [errorMessage(errors.notconnected)];
      }


      const receipt = await klist.methods.initialize(state.web3.utils.toChecksumAddress(k21address)).send({ from: state.account });

      if(receipt.status === true){
        return [successMessage('Initialized KLIST')];
      }else{
        return [errorMessage(errors.unsuccessful)];
      }
    }catch (err) {
      console.log("claim KLIST error: ");
      console.dir(err);
      return parseRPCError(err);
    }
  },
  claimN: async () => {
    console.log("claimN");
    try{

      // Get the contract instance.
      const n = new state.web3.eth.Contract(
        N.abi,
        state.web3.utils.toChecksumAddress(addresses.n),//deployedNetworkN.address,
      );

      console.log("nContract attached")

      const receipt = await n.methods.claim(44).send({ from: state.account });
      console.log("receipt")
      console.dir(receipt)
      if(receipt.status === true){
        return [successMessage('Claimed N token')];
      }else{
        return [errorMessage(errors.unsuccessful)];
      }
    }catch (err) {
      console.log("ERROR: ");
      console.dir(err);
      return parseRPCError(err);
    }
  },
  claimLoot: async () => {
    try{

      // Get the contract instance.
      const loot = new state.web3.eth.Contract(
        Loot.abi,
        state.web3.utils.toChecksumAddress(addresses.loot)
      );

      const receipt = await loot.methods.claim(44).send({ from: state.account });
      if(receipt.status === true){
        return [successMessage('Claimed Loot token')];
      }else{
        return [errorMessage(errors.unsuccessful)];
      }
    }catch (err) {
      console.log("ERROR:");
      console.dir(err);
      return parseRPCError(err);
    }
  },
  claimBloot: async () => {
    try{

      // Get the contract instance.
      const bloot = new state.web3.eth.Contract(
        Bloot.abi,
        state.web3.utils.toChecksumAddress(addresses.bloot)
      );

      const receipt = await bloot.methods.claim(44).send({ from: state.account });
      if(receipt.status === true){
        return [successMessage('Claimed Bloot token')];
      }else{
        return [errorMessage(errors.unsuccessful)];
      }
    }catch (err) {
      console.log("ERROR:");
      console.dir(err);
      return parseRPCError(err);
    }
  },
  manifestXe_ntity: async (tokenId) => {
    const { xe_ntity } = state;

    const uri = await xe_ntity.methods.tokenURI(tokenId).call();
    console.log('testing')
    console.log(uri)

    let payloadPrefix = 'data:application/json;base64,';
    let payload = uri.substring(payloadPrefix.length);
    payload = atob(payload);
    console.log(payload)
    let payloadJSON = JSON.parse(payload);
    let name = getNFTName(payloadJSON, "xe_ntity");
    payload = formatJSON(payloadJSON);

    let svgPrefix = 'data:image/svg+xml;base64,';
    let svg = payloadJSON.image.substring(svgPrefix.length);
    svg = atob(svg);

    return [successMessage("Manifested xe_entity\nid: " + tokenId + "\n" + payload), { type: 'svg', color: 'white', width: '300px', title: name, payload: svg}];
  },
  renderKlist: async (tokenId) => {
    try{
      const { klist } = state;

      if(parseInt(tokenId) === -1){
        tokenId = await klist.methods.tokenOfOwnerByIndex(state.account, 0).call();
      }

      const uri = await klist.methods.tokenURI(tokenId).call();

      let payloadPrefix = 'data:application/json;base64,';
      let payload = uri.substring(payloadPrefix.length);
      payload = atob(payload);
      //console.log(payload);
      let payloadJSON = JSON.parse(payload);
      let name = getNFTName(payloadJSON, "KLIST");
      payload = formatJSON(payloadJSON);

      let svgPrefix = 'data:image/svg+xml;base64,';
      let svg = payloadJSON.image.substring(svgPrefix.length);
      svg = atob(svg);

      return [successMessage("Rendered KLIST token\nid: " + tokenId + "\n" + payload), { type: 'svg', color: 'white', width: '300px', title: name, payload: svg}];
    }catch (err) {
      console.log("ERROR:");
      console.dir(err);

      return parseRPCError(err);
    }
  },
  adminAddDim: async (contractAddress) => {
    const { daemonica } = state;

    console.log('admin add dim with address: ' + state.web3.utils.toChecksumAddress(contractAddress))

    const add = await daemonica.methods.adminAddDim(state.web3.utils.toChecksumAddress(contractAddress)).send({ from: state.account });

    console.log("add")
    console.dir(add)

    return [successMessage('Admin added dim ' + contractAddress)];
  },
  addDim: async (contractAddress) => {
    const { daemonica } = state;

    console.log('add dim with address: ' + state.web3.utils.toChecksumAddress(contractAddress))

    const add = await daemonica.methods.addDim(state.web3.utils.toChecksumAddress(contractAddress)).send({ from: state.account });

    console.log("add")
    console.dir(add)

    return [successMessage('Added dim ' + contractAddress)];
  },
  removeDim: async (symbol) => {
    const { daemonica } = state;

    console.log("removing dim with symbol: " + symbol)

    if(symbol === "n"){
      symbol = "N";
    }

    console.log("removing dim with symbol: " + symbol)

    const remove = await daemonica.methods.adminRemoveDim(symbol).send({ from: state.account });

    console.log("remove")
    console.dir(remove)

    return [successMessage('Removed dim ' + symbol)];
  },
  entityWithdraw: async () => {
    const { entity } = state;

    console.log("entity withdrawing")

    await entity.methods.withdrawAvailableBalance().send({ from: state.account });

    return [successMessage('withdrew funds')];
  },
  xe_ntityWithdraw: async () => {
    const { xe_ntity } = state;

    console.log("xe_ntity withdrawing")

    await xe_ntity.methods.withdrawAvailableBalance().send({ from: state.account });

    return [successMessage('withdrew funds')];
  },
  daemonicaWithdraw: async () => {
    const { daemonica } = state;

    console.log("daemonica withdrawing")

    await daemonica.methods.ownerWithdrawAvailableBalance().send({ from: state.account });

    return [successMessage('Withdrew funds')];
  },
  getArtist: async () => {
    const { entity } = state;

    console.log("get artist")

    let artist = await entity.methods.owner().call();

    console.log("artist:")
    console.dir(artist)

    return [successMessage('Artist is: ' + artist)];
  },
  listDims: async () => {
    const { daemonica } = state;
    const dims = await daemonica.methods.getDims().call();

    return [systemMessage('Registered dims: ' + dims[0].join(', '))];
  },
  setPresale: async (val) => {
    const { daemonica } = state;
    const receipt = await daemonica.methods.setPresale(val).send({ from: state.account });
    console.log("setPresale:");
    console.dir(receipt);

    return [systemMessage('Presale set')];
  },
  getPresale: async () => {
    const { daemonica } = state;
    const receipt = await daemonica.methods.presale().call();
    console.log("getPresale:");
    console.dir(receipt);

    return [systemMessage('Presale: ' + receipt)];
  },

  renderGui: () => {
    return [systemMessage('All currently available entities have undergone animo, minting is paused for now\n\nEntity hodlers can do the following:\nType <strong>connect</strong> to connect your MetaMask\nType <strong>spells</strong> to explore the list of commands\nType <strong>entity cast --id=#</strong> (where # is the id of an entity you own) to spawn a xe_ntity and transform your entity\n\nStay tuned for more...')];
    //return [systemMessage(markdown.toHTML(gui.text.join('\n\n')))];
    //return [systemMessage("gui rendered"),{},{ html: markdown.toHTML(gui.text.join('\n\n')) }];
  },
  getLogo: () => {
    const logo ='<div id="logo"><object type="image/svg+xml" data="/daemonica.svg" class="logo">Daemonica Logo</object></div>';
    return { type: 'svg', color: 'red', left: '20', width: '225px', title: 'daemonica.svg', payload: logo };
  },
  getOrdo: () => {
    const ordo ='<img id="ordo" src="ordo.jpg" alt="ordo.jpg">';
    return { type: 'svg', color: 'red', left: '20', width: '403px', title: 'Untitled (2021), the artist', payload: ordo };
  },
  getEntityBanner: () => {
    const ordo ='<img class="banner" src="banner_entity.jpg" alt="banner_entity.jpg">';
    return { type: 'svg', color: 'red', left: '20', width: '888px', title: 'banner_entity.jpg', payload: ordo };
  },
  getXe_ntityBanner: () => {
    const ordo ='<img class="banner" src="banner_xe_ntity.jpg" alt="banner_xe_ntity.jpg">';
    return { type: 'svg', color: 'red', left: '20', width: '888px', title: 'banner_xe_ntity.jpg', payload: ordo };
  },
  distroKlist: async () => {
    try{
      const { klist } = state;
      if(klist === null){
        return [errorMessage(errors.notconnected)];
      }

      let addressesToClaim = [
        state.web3.utils.toChecksumAddress('0x2538fff9c6B0FE9D6985115Bc014DE0AF3E260d3'),
        state.web3.utils.toChecksumAddress('0x586ffc9b9ebd58e480d45b8cfd54509600e8b96a')
      ];

      let message = await klist.methods.proxyClaim(addressesToClaim).send({ from: state.account });

      return [systemMessage(message)];
    }catch (err) {
      console.log("ERROR:");
      console.dir(err);
      return parseRPCError(err);
    }
  },

}

export default exp;
