import React, { Component } from 'react';
import './App.css';

import Terminal from 'daemonica-interpreter';
import dynamicList from './commands/dynamic';
import staticList from './commands/static';



const cmd = { dynamicList, staticList }
const config = {
  prompt: '> ',
  version: '0.15.6',
  bootCmd: 'reboot'
}

class App extends Component {

  constructor(props) {
    super(props)

    this.state = {
      windows: [],
    }
  }

  handler = async (uri) => {
    console.log("***handler()");
    console.log(uri);
  }

  render() {
    return (
      <div className="main">
        <div id="grad"><div id="blur"></div></div>
        <React.StrictMode>
          <Terminal cmd={cmd} config={config} callbackFunc={this.handler}/>
        </React.StrictMode>
      </div>
    );
  }
}

export default App;

const fadeEl = document.querySelectorAll('.fade')
window.addEventListener('load', function () {
  fadeEl.forEach(e => e.classList.add('in'))
})

window.addEventListener('beforeunload', function () {
  fadeEl.forEach(e => e.classList.remove('in'))
})
